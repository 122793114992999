import React from 'react';

// Material Design
import { Grid, Row, Cell } from '@material/react-layout-grid';
import { Headline3, Headline6, Headline4 } from '@material/react-typography';

// Layout y componentes
import Layout from '../components/layout';

const PoliticaDePrivacidadPage = () => {
  return (
    <Layout>
      <div className="mdc-politica-de-privacidad">
        <Grid>
          <Row>
            <Cell columns={12}>
              <Headline3>Política de Privacidad</Headline3>
            </Cell>
          </Row>
          <Row>
            <Cell columns={2} />
            <Cell columns={8}>
              <Headline6>
                En CMUJER, en adelante este sitio web , respetamos su
                información personal y en vista de cumplir con las políticas de
                seguridad respectivas concernientes a todo sitio web, que
                debería ser obligatorias, informo a usted lo siguiente:
              </Headline6>
              <Headline4>Privacidad de datos personales:</Headline4>
              <Headline6>
                Sus datos personales le corresponden solo a usted y este sitio
                web es responsable de no revelar ninguna clase de información
                que le pertenezca (como email, números de ip, etc.), salvo su
                expresa autorización o fuerzas de naturaleza mayor de tipo legal
                que lo involucren, como hackeos o suplantaciones.
              </Headline6>
              <Headline4>Seguridad de su información personal:</Headline4>
              <Headline6>
                Este sitio web se hace responsable de velar por su seguridad,
                por la privacidad de su información y por el respeto a sus
                datos, de acuerdo con las limitaciones que la actual internet
                nos provee, siendo conscientes que no estamos excluídos de
                sufrir algún ataque por parte de crackers o usuarios
                malintencionados que ejerzan la delincuencia informática.
              </Headline6>
              <Headline4>Obtención de su información: </Headline4>
              <Headline6>
                Todos sus datos personales consignados en este sitio son
                suministrados por usted mismo, haciendo uso de su libertad. La
                información aquí almacenada solo comprende datos básicos
                ingresados mediante formularios de contacto, comentarios u otros
                similares.
              </Headline6>
              <Headline4>Comunicaciones de usuario:</Headline4>
              <Headline6>
                Cuando envía mensajes de correo electrónico y otras
                comunicaciones a CMUJER, podríamos conservarlas a fin de
                procesar sus consultas,responder a sus peticiones y mejorar
                nuestros servicios.
              </Headline6>
              <Headline4>Uso de la información:</Headline4>
              <Headline6>
                Al proporcionarnos sus datos personales, estando de acuerdo con
                la Política de Privacidad aquí consignada, nos autoriza para el
                siguiente uso de su información:
                <ul>
                  <li> para el fin mismo por lo cual se ha suministrado; </li>
                  <li>
                    para considerarlo dentro de nuestras estadísticas de
                    tráfico;
                  </li>
                  <li>
                    para enviar e-mails con nuestros boletines, responder
                    inquietudes o comentarios, y mantenerlo informado.
                  </li>
                </ul>
              </Headline6>
              <Headline4>Uso de las cookies</Headline4>
              <Headline6>
                El uso de cookies y su dirección IP, tomados por este sitio, se
                realiza solo con la finalidad de mantenerles un sitio de acuerdo
                a sus preferencias locales(tales como navegador web usado,
                sistema operativo, ISP, etc.). Las cookies permiten entregar un
                contenido ajustado a los intereses y necesidades de nuestros
                usuarios/visitantes.
              </Headline6>
              <Headline4>
                Modificaciones de nuestras Políticas de Privacidad:
              </Headline4>
              <Headline6>
                El sitio web se reserva el derecho de modificar, rectificar,
                alterar o eliminar cualquier punto del presente escrito en
                cualquier momento y sin previo aviso, siendo su responsabilidad
                el mantenerse informado del mismo para una adecuada
                administración de su información.
              </Headline6>
            </Cell>
          </Row>
        </Grid>
      </div>
    </Layout>
  );
};

export default PoliticaDePrivacidadPage;
